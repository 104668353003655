<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from 'axios';
// import { required, email, minLength } from "vuelidate/lib/validators";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import vSelect from 'vue-select'
import { required, minValue } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Cobranças",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, vSelect },
  data() {
    return {
      title: "Cobranças",
      items: [
        {
          text: "Cobranças",
          active: true
        }
      ],
      bills: [],
      finances: [],
      loadingFinances: true,
      customers: {
        name: "",
        email: "",
        password: "",
      },
      submitted: false,
      showmodal: false,
      user: null,
      checked: true,
      loading: true,
      search: null,
      options: [],
      bill: {
          "value": 0,
          "seller": {
              "id": "",
          },
          "client": {
              "id": "",
          },
          "total_installments": 0,
          "installments_entrance": 0,
          "description": "",
          "notify_client": true,
          "document_generate": false,
          "due_date": ""
      },
      parcelas: [],
      modalParcelas: false,
      invoiceUrl: null,
      billDetails: false,
      billDetailsModal: false
    };
  },
  validations: {
    bill: {
      total_installments: {required, minValue: minValue(1)},
      description: {required},
      due_date: {required},
      value: {required},
      installments_entrance: {required, minValue: minValue(1)},
      seller: {
        id: {required}
      },
      client: {
        id: {required}
      }
    }
  },
  created(){
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getData()

    this.invoiceUrl = `${process.env.VUE_APP_INVOICE}external/bill/promissory/`

    this.bill.due_date = this.$moment().format('YYYY-MM-DD')

    //Receive Finances values
    this.loadingFinances = true
    let config = {
      method: 'get',
      url: `${process.env.VUE_APP_API}api/v1/admin/dashboard/finance`,
      headers: { 
        'Accept': 'application/json', 
        'Authorization': `Bearer ${this.user.access_token}`
      }
    };
    axios(config)
    .then( (response) => {
      this.loadingFinances = false
      this.finances = response.data.data
    })
    .catch(function (error) {
      console.log(error);
    });
  },
  methods:{
    getData(url){
      this.loading = true
      let config = {
        method: 'get',
        url: url ? url : `${process.env.VUE_APP_API}api/v1/admin/bill`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        }
      };

      axios(config)
      .then( (response) => {
        this.loading = false
        this.bills = response.data.data
        
      })
      .catch(function (error) {
        console.log(error);
      });
    },

    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true

        let newBill = JSON.parse(JSON.stringify(this.bill))

        newBill.seller.id = newBill.seller.id.code
        newBill.client.id = newBill.client.id.code

        let config = {
          method: 'POST',
          url: `${process.env.VUE_APP_API}api/v1/admin/bill`,
          headers: { 
            'Accept': 'application/json', 
            'Authorization': `Bearer ${this.user.access_token}`,
          },
          data: newBill
        };
        axios(config)
        // eslint-disable-next-line no-unused-vars
        .then( response => {
          this.loading = false
          this.submitted = false
          this.bill = {
              "value": 0,
              "seller": {
                  "id": "",
              },
              "client": {
                  "id": "",
              },
              "total_installments": 1,
              "description": ""
          }
          this.$bvToast.toast('Cobrança adicionada com sucesso', {
            title: 'Tudo certo!',
            variant: 'success',
            solid: true
          });
          this.showmodal = false;
          this.getData()
        })
        // eslint-disable-next-line no-unused-vars
        .catch( (error) => {
          console.log(error.response)
          this.loading = false
          this.$bvToast.toast('Verifique os campos digitados e tente novamente', {
            title: 'Ocorreu um erro',
            variant: 'danger',
            solid: true
          });
        });
      }
      
    },
    changeStatus(status, id){
      let config = {
        method: 'PUT',
        url: `${process.env.VUE_APP_API}api/v1/lot/disable/${id}`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        },
        data:{
          enable: status
        }
      };

      axios(config)
      .then( (response) => {
        response
      })
    },
    deleteLot(id){
      Swal.fire({
        title: "Tem certeza?",
        text: "Esse registro será deletado permanentemente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, excluir!",
        cancelButtonText: "Cancelar!"
      }).then(result => {
        if (result.value) {
          let config = {
            method: 'DELETE',
            url: `${process.env.VUE_APP_API}api/v1/lot/${id}`,
            headers: { 
              'Accept': 'application/json', 
              'Authorization': `Bearer ${this.user.access_token}`
            }
          };
          axios(config)
          .then( () => {
            this.getData()
            Swal.fire("Excluído!", "O registro já foi removido.", "success");
          })
          
        }
      });
    },
    paginate(value){
      let link = this.bills.links[value].url
      this.getData(link)
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },
    async fetchOptions (search, loading) {
      if (search.length < 1) return false;
      loading(true)
      let config = {
        method: 'GET',
        url: `${process.env.VUE_APP_API}api/v1/autocomplete/users?search=${search}`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`,
        }
      };
      await axios(config)
      .then( response => {
        this.options = []
        response.data.data.map( item => {
          if (item.identity) {
            this.options.push({label: `${item.name} (${item.identity})`, code: item.uuid})
          }
        })

        loading(false)
      })

    },
    setSelected(){
      this.options = []
    },
    showParcelas(parcelas){
      this.parcelas = parcelas
      this.modalParcelas = true
    },
    showDetails(bill){
      this.billDetails = bill
      this.billDetailsModal = true
    },
    onQueryChange() {
      if (this.search.length == 0) {
        this.getData()
      }
    },
    dismissInstallment(installment) {
      Swal.fire({
        title: "Tem certeza?",
        text: "Esse registro será baixado permanentemente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Sim, baixar!",
        cancelButtonText: "Cancelar!"
      }).then(result => {
        if (result.value) {
          
          let config = {
            method: 'POST',
            url: `${process.env.VUE_APP_API}api/v1/admin/bill/installment/dismiss`,
            headers: { 
              'Accept': 'application/json', 
              'Authorization': `Bearer ${this.user.access_token}`
            },
            data:{
              id: installment.uuid
            }
          };
          // console.log(config)
          axios(config)
          .then( () => {
            this.modalParcelas = false
            this.getData()
            Swal.fire("Tudo certo!", "A parcela foi baixado com sucesso.", "success");
          })
          
        }
      });
    },
    chargeForMoney(installment){
      this.loading = true
      let config = {
        method: 'POST',
        url: `${process.env.VUE_APP_API}api/v1/admin/bill/installment/charge-for-money`,
        headers: { 
          'Accept': 'application/json', 
          'Authorization': `Bearer ${this.user.access_token}`
        },
        data: installment
        
      };
      // console.log(config)
      axios(config)
      .then( () => {
        this.loading = false
        Swal.fire("Tudo certo!", "A cobrança foi enviada com sucesso com sucesso.", "success");
      })
    },

    copyWhatsappText(item){
      item
      let text = `Prezado(a) Sr(a). ${this.billDetails.client.name},%0a%0aConsta em nossos registros que o pagamento referente à compra do ${this.billDetails.description.replace('&', '')}, com vencimento no dia ${this.$moment(item.due_date).utc().format('DD/MM/YYYY')}, ainda se encontra em aberto. Dessa forma fazemos o uso do presente para informá-lo do débito pendente e solicitar sua mais breve regularização.%0a%0aCaso o pagamento já tenha sido realizado, solicitamos cópia do comprovante para atualização dos nossos registros.%0a%0aVisualizar fatura: ${this.invoiceUrl}${this.billDetails.uuid}`
      window.open(
          `https://api.whatsapp.com/send?phone=${this.billDetails.client.phone}&text=${text}`,
          '_blank')
      .focus();
    },

    copyWhatsappText2(item){
      item
      let text = `Prezado(a) Sr(a). ${this.billDetails.client.name},%0a%0aEspero que esta mensagem o encontre bem. Gostaríamos de lembrá-lo sobre a proximidade do vencimento da parcela da compra do ${this.billDetails.description.replace('&', '')}, programada para o dia ${this.$moment(item.due_date).utc().format('DD/MM/YYYY')}. Recomendamos que efetue o pagamento antecipado para evitar possíveis encargos financeiros, como juros e multas.%0a%0aPara visualizar a fatura e realizar o pagamento, por favor, acesse o seguinte link: ${this.invoiceUrl}${this.billDetails.uuid}. Estamos à disposição para auxiliá-lo em caso de dúvidas ou para fornecer informações adicionais sobre o pagamento.%0a%0aAgradecemos pela sua atenção a este lembrete e pela pontualidade no cumprimento de suas obrigações financeiras. Se já efetuou o pagamento, pedimos desculpas pelo lembrete e agradecemos pela sua cooperação.%0a%0aAtenciosamente,%0a%0aPadok Agrotech`
      window.open(
          `https://api.whatsapp.com/send?phone=${this.billDetails.client.phone}&text=${text}`,
          '_blank')
      .focus();
    },
    callWp(bill){
      let text = `Olá Sr(a). ${bill.client.name},%0a%0aReferente à compra do ${bill.description.replace('&', '')}%0a%0a`
      window.open(
          `https://api.whatsapp.com/send?phone=${bill.client.phone}&text=${text}`,
          '_blank')
      .focus();
    }
  },
  watch: {
    search: function (val) {
      if (val.length >= 3) {
        this.getData(`${process.env.VUE_APP_API}api/v1/admin/bill?search=${val}`)
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <b-overlay :show="loadingFinances" rounded="sm">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Saldo Total</p>
                  <h4 class="mb-0">R$ {{finances.in_cashier | price}}</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-wallet-line font-size-24"></i>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <b-overlay :show="loadingFinances" rounded="sm">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">A receber (30 dias)</p>
                  <h4 class="mb-0">R$ {{finances.total_in_30_days | price}}</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-shopping-bag-line font-size-24"></i>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <b-overlay :show="loadingFinances" rounded="sm">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">Total PADOK (30 dias)</p>
                  <h4 class="mb-0">R$ {{finances.padok_taxes_in_30_days | price}}</h4>
                </div>
                <div class="text-primary">
                  <i class="ri-funds-line font-size-24"></i>
                </div>
              </div>
            </b-overlay>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-sm-12">
        <div class="card h-100">
          
          <div class="card-body">
            <div class="row">
              <div class="col-sm-8">
                <b-form-group
                  id="example-search"
                >
                  <b-form-input
                    id="search"
                    type="search"
                    v-model="search"
                    @input="onQueryChange"
                    placeholder="Buscar..."
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-sm-4">
                <a href="javascript:void(0);" class="btn btn-success mb-2" @click="showmodal = true">
                  <i class="mdi mdi-plus mr-2"></i> Nova cobrança
                </a>
                
              </div>
            </div>
            
            <div class="table-responsive mt-3">
              <b-overlay :show="loading" rounded="sm">
                <table
                  class="table table-centered datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 20px;">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customercheck" />
                          <label class="custom-control-label" for="customercheck">&nbsp;</label>
                        </div>
                      </th>
                      <th>Identificador</th>
                      <th>Valor</th>
                      <th>Comprador</th>
                      <th>Vendedor</th>
                      <th>Parcelas</th>
                      <th>Status</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in bills.data" :key="index"  :class="item.delayed ? 'table-danger' : ''">
                      <td>
                        <div class="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            :id="`customercheck${index}`"
                          />
                          <label class="custom-control-label" :for="`customercheck${index}`">&nbsp;</label>
                        </div>
                      </td>
                      <td>{{item.description}}</td>
                      <td>R$ {{item.amount | price}}</td>
                      <td>{{item.client.name}}</td>
                      <td>{{item.user.name}}</td>
                      <td>{{`${item.installments_entrance}+${item.installments_rest}`}}</td>
                      <td>{{item.status_text}}</td>
                      
                      <td>
                        <button @click="showDetails(item)" class="btn btn-secondary btn-sm mr-2">
                          <i class="ri-file-list-3-fill"></i> Detalhes
                        </button>

                        <a :href="invoiceUrl + item.uuid" target="_blank" class="btn btn-info btn-sm">
                          <i class=" ri-currency-line"></i> Relatório
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-overlay>

              <b-pagination
                class="mt-4"
                v-model="bills.current_page"
                :total-rows="bills.total"
                :per-page="bills.per_page"
                aria-controls="my-table"
                align="center"
                @change="paginate"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Adicionar cobrança"
      title-class="text-dark font-18"
      hide-footer
    >
      <b-overlay :show="loading" rounded="sm">
        <form @submit.prevent="handleSubmit">
          <div class="form-group">
            <label>Descrição / Identificador</label>
            <input v-model="bill.description" type="text" class="form-control" :class="{ 'is-invalid': submitted && $v.bill.description.$error }"/>
            <div
              v-if="submitted && !$v.bill.description.required"
              class="invalid-feedback"
            >Insira um identificador</div>
          </div>

          <div class="form-group row">
            <div class="col-sm-6">
              <label>Valor</label>
              <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">R$</span>
                <input type="text" class="form-control" v-model="bill.value" :class="{ 'is-invalid': submitted && $v.bill.value.$error }">
                <div
                  v-if="submitted && !$v.bill.value.minValue"
                  class="invalid-feedback"
                >Insira um valor</div>
              </div>
            </div>
            <div class="col-sm-6">
              <label>Data primeiro vencimento</label>
              <input v-model="bill.due_date" type="date" class="form-control" :class="{ 'is-invalid': submitted && $v.bill.due_date.$error }">
              <div
                  v-if="submitted && !$v.bill.due_date.required"
                  class="invalid-feedback"
                >Insira a quantidade de parcelas na entrada</div>
            </div>
          </div>

          <div class="form-group row">

            <div class="col-sm-6">
              <label>Parcelas</label>
              <input v-model="bill.total_installments" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.bill.total_installments.$error }">
                <div
                  v-if="submitted && !$v.bill.total_installments.minValue"
                  class="invalid-feedback"
                >Insira a quantidade de parcelas</div>
            </div>
            <div class="col-sm-6">
              <label>Entrada (em parcelas)</label>
              <input v-model="bill.installments_entrance" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.bill.installments_entrance.$error }">
              <div
                  v-if="submitted && !$v.bill.installments_entrance.minValue"
                  class="invalid-feedback"
                >Insira a quantidade de parcelas na entrada</div>
            </div>
          </div>

          <div class="form-group">
            <label for="">Vendedor</label>
            <v-select @search="fetchOptions" v-model="bill.seller.id" @input="setSelected" :options="options">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                  :class="{ 'is-invalid': submitted && $v.bill.seller.id.$error }">
                  <div
                    v-if="submitted && !$v.bill.seller.id.required"
                    class="invalid-feedback mt-3"
                  >Selecione um vendedor</div>
              </template>
            </v-select>
          </div>

          <div class="form-group mt-4">
            <label for="">Comprador</label>
            <v-select @search="fetchOptions" v-model="bill.client.id" @input="setSelected" :options="options">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  v-bind="attributes"
                  v-on="events"
                  :class="{ 'is-invalid': submitted && $v.bill.client.id.$error }">
                  <div
                    v-if="submitted && !$v.bill.client.id.required"
                    class="invalid-feedback mt-3"
                  >Selecione um comprador</div>
              </template>
            </v-select>
          </div>

          <div class="form-group mt-4">
            <b-form-checkbox v-model="bill.notify_client">
              Notificar partes
            </b-form-checkbox>

            <b-form-checkbox v-model="bill.document_generate">
              Gerar contrato para assinatura
            </b-form-checkbox>
          </div>
          
          
          <div class="text-right">
            <button type="submit" class="btn btn-success">Salvar</button>
            <b-button class="ml-1" variant="danger" @click="hideModal">Cancelar</b-button>
          </div>
        </form>
      </b-overlay>
    </b-modal>
    <!-- end modal -->
    
    <b-modal size="lg" id="modalParcelas" title="Parcelas" title-class="font-18" v-model="modalParcelas" scrollable>
      <table
        class="table table-centered datatable dt-responsive nowrap"
        style="border-collapse: collapse; border-spacing: 0; width: 100%;"
      >
        <thead class="thead-light">
          <tr>
            <th>Vencimento</th>
            <th>Valor</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in parcelas" :key="index" :class="$moment(item.due_date).isBefore($moment()) && item.status == 1 ? 'table-danger' : ''">
            <td>
              {{ item.due_date | date}}
            </td>
            <td>R$ {{item.amount | price}}</td>
            <td>{{item.status_text}}</td>
            <td>
            
              <b-button class="ml-1" variant="success" @click="dismissInstallment(item)" v-if="item.status == 1">Dar baixa</b-button>

              <b-button class="ml-1" variant="danger" @click="chargeForMoney(item)" v-if="item.status == 1 && $moment(item.due_date).isBefore($moment())">
                 <i class="dripicons-mail"></i> Cobrar
              </b-button>
              <b-button class="ml-1" variant="danger" @click="copyWhatsappText(item)" v-if="item.status == 1 && $moment(item.due_date).isBefore($moment())">
                 <i class=" fab fa-whatsapp"></i> Cobrar
              </b-button>

              <b-button class="ml-1" variant="warning" @click="copyWhatsappText2(item)" v-if="item.status == 1 && !$moment(item.due_date).isBefore($moment())">
                 <i class=" fab fa-whatsapp"></i> Alertar vencimento
              </b-button>
              <div v-if="item.transaction && item.transaction.paid">
                <strong>Data:</strong> {{ item.transaction.paid_in | date}}
                <br>
                <strong>Método:</strong> {{ item.transaction.form_payment_text ? item.transaction.form_payment_text : 'Baixado manualmente' }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <template #modal-footer="{ ok }">
        <b-button variant="success" @click="ok()">
          Fechar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="bill-details" size="xl" :title="billDetails.description" title-class="font-18" v-model="billDetailsModal" scrollable>

      <div v-if="billDetails">
        <b-alert variant="danger" class="py-4" :show="billDetails.delayed">Há parcelas em atraso para essa cobrança</b-alert>
        <div class="row mt-4">
          <div class="col-sm-3">
            <div class="media mt-4 mt-md-0">
              <div class="avatar-sm mr-3">
                <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                  <i class="ri-money-dollar-circle-line"></i>
                </span>
              </div>
              <div class="media-body align-self-center overflow-hidden">
                <h5>R$ {{billDetails.amount | price}}</h5>
                <p class="text-muted mb-0">Valor total</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="media">
              <div class="avatar-sm mr-3">
                <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                  <i class="ri-checkbox-circle-line"></i>
                </span>
              </div>
              <div class="media-body align-self-center overflow-hidden">
                <h5>{{ billDetails.status_text }}</h5>
                <p class="text-muted mb-0">Status</p>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="media mt-4 mt-md-0">
              <div class="avatar-sm mr-3">
                <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                  <i class="ri-calendar-fill"></i>
                </span>
              </div>
              <div class="media-body align-self-center overflow-hidden">
                <h5>{{ billDetails.created_at | date}}</h5>
                <p class="text-muted mb-0">Criado em</p>
              </div>
            </div>
          </div>

          <div class="col-sm-3">
            <div class="media mt-4 mt-md-0" style="cursor: pointer;" @click="callWp(billDetails)">
              <div class="avatar-sm mr-3">
                <span class="avatar-title bg-light rounded-circle text-primary font-size-24">
                  <i class=" fab fa-whatsapp"></i>
                </span>
              </div>
              <div class="media-body align-self-center overflow-hidden">
                <h5>Conversar</h5>
              </div>
            </div>
          </div>

         
        </div>

        <div class="border p-4 rounded mt-3">
          <div class="media border-bottom py-2">
            <div class="media-body">
              <p class="text-muted mb-2">Identificador</p>
              <h5 class="font-size-15 mb-3">
                {{ billDetails.uuid }}
              </h5>
            </div>
          </div>

          <div class="media border-bottom py-2">
            <div class="media-body">
              <p class="text-muted mb-2">Comprador</p>
              <h5 class="font-size-15 mb-3">
                {{ billDetails.client.name }}
              </h5>

              <ul class="list-inline product-review-link mb-0">
                <li class="list-inline-item">
                  <a href="#">
                    <b>CPF: </b> {{ billDetails.client.identity }}
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <b>EMAIL: </b> {{ billDetails.client.email }}
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <b>TELEFONE: </b> {{ billDetails.client.phone }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="media border-bottom py-2">
            <div class="media-body">
              <p class="text-muted mb-2">Vendedor</p>
              <h5 class="font-size-15 mb-3">
                {{ billDetails.user.name }}
              </h5>

              <ul class="list-inline product-review-link mb-0">
                <li class="list-inline-item">
                  <a href="#">
                    <b>CPF: </b> {{ billDetails.user.identity }}
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <b>EMAIL: </b> {{ billDetails.user.email }}
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <b>TELEFONE: </b> {{ billDetails.user.phone }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="media border-bottom py-2">
            <div class="media-body">
              <p class="text-muted mb-2">Especificações</p>
              <h5 class="font-size-15 mb-3">
                {{ billDetails.description }}
              </h5>

              <ul class="list-inline product-review-link mb-0">
                <li class="list-inline-item">
                  <a href="#">
                    <b>VALOR TOTAL: </b> R$ {{billDetails.amount | price}}
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <b>SITUAÇÃO: </b> {{ billDetails.status_text }}
                  </a>
                </li>
                <li class="list-inline-item">
                  <a href="#">
                    <b>PARCELAS: </b> {{`${billDetails.installments_entrance}+${billDetails.installments_rest}`}}
                  </a>
                </li>
              </ul>
            </div>
          </div>


          <div>
            <button @click="showParcelas(billDetails.installments)" class="btn btn-secondary my-3">
              <i class="ri-file-list-3-fill"></i> Parcelas
            </button>
            <a :href="billDetails.document.client_link" target="_blank" class="btn btn-warning ml-3" v-if="billDetails.document">
              Contrato comprador
            </a>
            <a :href="billDetails.document.seller_link" target="_blank" class="btn btn-info ml-3" v-if="billDetails.document">
              Contrato vendedor
            </a>
            
          </div>
        </div>
      </div>

      
      <template #modal-footer="{ ok }">
        <b-button variant="success" @click="ok()">
          Fechar
        </b-button>
      </template>
    </b-modal>


  </Layout>
</template>